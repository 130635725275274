import { forwardRef } from 'react';
import { closeSnackbar, CustomContentProps } from 'notistack';

import {
    Alert,
    AlertColor,
    Box,
    SxProps,
    Theme,
} from '@thirdspacelearning/library';

declare module 'notistack' {
    interface VariantOverrides {
        curriculumSnackbar: {
            severity?: AlertColor;
            title?: string;
            color?: string;
            testId?: string;
            sxProps?: SxProps<Theme>;
        };
    }
}

interface CurriculumSnackbarProps extends CustomContentProps {
    severity?: AlertColor;
    title?: string;
    color?: string;
    testId?: string;
    sxProps?: SxProps<Theme>;
}

const CurriculumSnackbar = forwardRef<HTMLDivElement, CurriculumSnackbarProps>(
    (props, ref) => {
        const {
            id,
            message,
            severity = 'success',
            title,
            sxProps,
            testId,
        } = props;

        const handleClose = () => {
            closeSnackbar(id);
        };

        return (
            <Alert
                ref={ref}
                severity={severity}
                title={title}
                data-testid={testId || 'alert'}
                onClose={handleClose}
                sx={{ ...sxProps }}
            >
                {message && <Box data-testid="alert-message">{message}</Box>}
            </Alert>
        );
    },
);

export default CurriculumSnackbar;
